<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
			:slot_table_list='["visit_total","now_num","last_num", "repeat_num","name","materials","freezing","box","not_freezing"]'
			ref="list" :is_page="false" rowKey="id" :submit_preprocessing="submit_preprocessing"
			@expandedRowsChange="expandedRowsChange($event)" @list_after="list_after">
			<template slot="now_num">{{now_num1}}</template>
			<template slot="last_num">{{last_num1}}</template>
			<template slot="name">名称</template>
			<template slot="repeat_num">重复拜访数</template>
		</TableList>
	</div>
</template>

<script>
	import TableList from "@/components/TableList";
	import {
		userPhoto,
		userPhotoSon,
		VisitRepeatgetSub,
		customerVisitRepeat
	} from "@/api/customer";
	const columns = [{
			scopedSlots: {
				title: 'name'
			},
			dataIndex: 'name',
		},
		{
			scopedSlots: {
				title: 'last_num'
			},
			dataIndex: 'last_num',
		},
		{
			scopedSlots: {
				title: 'now_num'
			},
			dataIndex: 'now_num',
		},
		{
			scopedSlots: {
				title: 'repeat_num'
			},
			dataIndex: 'repeat_num',
		},
	];
	export default {
		name: "Index",
		components: {
			TableList,
		},
		data() {
			return {
				now_num1:'',
				last_num1:'',
				get_table_list: customerVisitRepeat,
				submit_preprocessing: {
					array_to_string: ['department','role_id']
				},
				order_details: {
					goods: [],
				},
				columns,
				form_data_seo: {
					list: [{
							type: "tree-select",
							name: "department",
							title: "部门",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: "tree-select",
							name: "role_id",
							title: "岗位职务",
							options: {},
							treeData: [],
							multiple: true
						},
						// {
						// 	type: "select",
						// 	name: "role_id",
						// 	title: "岗位职务",
						// 	mode: "default",
						// 	options: {},
						// 	list: []
						// },
						{
							type: "text",
							name: "username",
							title: "姓名",
							placeholder: '姓名/账号/工号/手机号',
							mode: "default",
							options: {},
							list: []
						},
						{
							type: "select",
							name: "user_status",
							title: "在职状态",
							mode: "default",
							options: {},
							list: this.$config.user_status_list
						},
						{
							type: "month",
							name: "start_time",
							title: "第一个月",
							options: {
								initialValue: "",
								defaultValue:''
							}
						},
						{
							type: "month",
							name: "end_time",
							title: "第二个月",
							options: {
								initialValue: '',
								defaultValue:''
							}
						},
					],
					...this.$config.form_data_seo
				},
				form_data: {}
			};
		},
		async created() {
			this.$method.get_department().then(res => {
				this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department',
					'treeData', res)
			});

			this.$method.get_role_tree().then(res => {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "role_id") {
						item.treeData = res;
					}
				});
			});

			// this.$method.get_role().then(res => {
			// 	this.form_data_seo.list.forEach(item => {
			// 		if (item.name == "role_id") {
			// 			item.list = [...this.$config.status_all, ...res];
			// 		}
			// 	});
			// });
			this.now_data()
		},
		methods: {
			now_data() {
				var date = new Date();
				let year = date.getFullYear(); //获取完整的年份(4位)
				let month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
				if (month < 10) {
					month = '0' + month
				}
				var t = year + '-' + month
				let t2 = this.get_last_data(t)
				this.now_num1 = t
				this.form_data_seo.list[4].options.defaultValue =  t2
				this.form_data_seo.list[5].options.defaultValue =  t
				this.last_num1 = t2
			},
			get_last_data(date) {
				var arr = date.split('-');
				var year = arr[0]; //获取当前日期的年份
				var month = arr[1]; //获取当前日期的月份
				var year2 = year;
				var month2 = parseInt(month) - 1;
				if (month2 == 0) { //如果是1月份，则取上一年的12月份
					year2 = parseInt(year2) - 1;
					month2 = 12;
				}
				if (month2 < 10) {
					month2 = '0' + month2; //月份填补成2位。
				}
				var t2 = year2 + '-' + month2
				return t2;
			},
			see({
				record
			}, type) {

				this.$router.push({
					path: "/imitation/index",
					query: {
						[record.type == 2 ? 'department' : 'uid']: record.id,
						data_type: type,
						visit_date: record.visit_time,
						...this.$refs.list.seo_data,
					}
				});
			},
			get_list_key(list, key, index_arr = []) {
				for (let i = 0; i < list.length; i++) {
					if (list[i].id == key) {
						return list[i]
					}

					if (Array.isArray(list[i].children) && list[i].children.length) {
						let value = this.get_list_key(list[i].children, key);
						if (value !== false) {
							return value;
						}
					}
				}

				return false;
			},
			expandedRowsChange(e) {
				e.forEach(key => {
					let list = JSON.parse(JSON.stringify(this.$refs.list.list));
					let item = this.get_list_key(list, key);
					if (!item.hasOwnProperty('is_req')) {
						VisitRepeatgetSub({
							data: {
								department_id: key,
								...this.$refs.list.seo_data
							}
						}).then(res => {

							item.children = [
							
								...res.data.list.map((vo) => {
									vo.children = [];

									return vo;
								})

							];
							item.is_req = true;
							this.$refs.list.set_data('list', list);
						})
					}
				});
			},
			list_after(res) {
				if(!this.$refs.list.seo_data.end_time){
					this.now_data()
				}
				if(this.$refs.list.seo_data.start_time){
					this.last_num1 = this.$refs.list.seo_data.start_time
				}
				if(this.$refs.list.seo_data.end_time){
					this.now_num1 = this.$refs.list.seo_data.end_time
				}
				let list = this.$refs.list.list;
				list = list.map(item => {
					item.children = [];
					return item;
				});

			},
		}
	};
</script>

<style lang="less">

</style>
